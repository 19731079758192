import { Alert, Backdrop, Box, Button, Checkbox, CircularProgress, Grid, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Modal, Snackbar, TextField, Tooltip, Typography, FormControl, InputLabel, Select, MenuItem, AlertTitle } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { convUnixDate, ins, mkSerialNo, sendDataToServer } from '../utils/functions'
import SimCardIcon from '@mui/icons-material/SimCard';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import LockResetIcon from '@mui/icons-material/LockReset';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SendToMobileIcon from '@mui/icons-material/SendToMobile';
import Cookies from 'js-cookie';
import SystemUpdateAltTwoToneIcon from '@mui/icons-material/SystemUpdateAltTwoTone';
import CheckSmartcard from '../components/CheckSmartcard';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PagesSmartcard from '../components/PagesSmartcard'
import AlertNothing from '../components/AlertNothing';
import FileUpload from '../components/FileUpload';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import BackspaceIcon from '@mui/icons-material/Backspace';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import KeyIcon from '@mui/icons-material/Key';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import { Link } from 'react-router-dom';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';




export default function SmartcardsPage(props){

    const [search, setSearch] = useState("")
    const [sm, setSm] = useState([])
    const [del, setDel] = useState(0)
    const [edit, setEdit] = useState({})
    const [newRowHighlighted, setNewRowHighlighted] = useState(false);
    const [multiple, setMultiple] = useState(false)
    const [multipleValue, setMultiplevalue] = useState("10")
    const [cchk, setCchk] = useState([])
    const refcchk = useRef()
    const [cchk0, setCchk0]  = useState(false)
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, finishDate] = dateRange;
    const [page, setPage] = useState(0) // это сдвиг в SQL запросе DESC LIMIT page,35
    const [upd, setUpd] = useState(0)
    const [isLoading, setIsloading] = useState(false)
    const [fwError, setFwError] = useState({'text':"", 'status':false})
    const [mInfo, setMinfo] = useState("")
    const [paramsNewcamd, setParamsNewcamd] = useState(["", {}])
    let last_mm = useRef(0)
    const time = Math.floor(Date.now() / 1000)

    //let pin = useRef(0)


    useEffect(function(){
        sendDataToServer({
            op: "getSmartcards",
            page: page
        }).then(r => {
            setSm(r)
            setRefcchk(r)
        })
        // sendDataToServer({op: "getPin"}).then(r =>{
        //     pin.current = parseInt(r.data.id)
        // })
    }, [del, page, upd])


    function setRefcchk(r){
        refcchk.current = []
        r.forEach(function(v,k){
            refcchk.current.push(parseInt(v["id"]))
        })
    }



    function handleAdd(){
        sendDataToServer({
            op: "addSmartcard"
        }).then(r => {
            setSm(r)
            setRefcchk(r)
            //console.log(r)
            setNewRowHighlighted(true)
            setTimeout(() => {
                setNewRowHighlighted(false);
            }, 1000);
        })
    }







    function handleSearch(e){
        const v = e.target.value
        sendDataToServer({
            op: "searchSmartcards",
            search: v
        }).then(r => {
            setSearch(v)
            setCchk([])
            setCchk0(false)
            //console.log(refcchk0.current.querySelector('input').checked)
            if (r.length > 0){
                setSm(r)
                setRefcchk(r)
            }
        })
    }


    function handleDelete(id){

        let s = ""
        let ids = id
        if(id < 0){
            s = "s"
            ids = cchk
        }
        const send = {
            op: "deleteSmartcard" + s,
            id: ids
        }

        sendDataToServer(send).then(r => {
            if (r.status === "OK"){
                setDel(0)
                setCchk0(false)
                setCchk([])
            }
        })
    }


    function handleClickEdit(v){
        const need = ['id', 'serial_no', 'descr', 'access_criteria', 'start', 'finish', 'mode']
        var vnew = {};
        sendDataToServer({
            op: "getPackages"
        }).then(r => {
            need.forEach(function(vv) {
                vnew[vv] = v[vv];
            });

            last_mm.current = vnew.mode

            let idsPcks = getIdsPckgs(vnew["access_criteria"])
            r.forEach(function(v, k) {
                if (idsPcks.includes(parseInt(v["id"]))){
                    r[k]["on"] = true
                }else{
                    r[k]["on"] = false
                }
            })
            vnew["packages"] = r
            setEdit(vnew)
            //setDateRange([new Date(parseInt(vnew["start"]) * 1000), new Date((parseInt(vnew["finish"])-86399) * 1000)])
            setDateRange([new Date(parseInt(vnew["start"]) * 1000), new Date(parseInt(vnew["finish"]) * 1000)])
            //console.log(vnew)
        })

    }

    function handleSendForm(e){
        setMinfo("")
        e.preventDefault();
        let packs = edit["packages"]
        delete edit["packages"]
        let intPacks = 0
        packs.forEach(function(v,k){
            if(v["on"]===true){
                intPacks += Math.pow(2, parseInt(v["id"])-1);
            }
        })
        let out = intPacks.toString(16)
        edit["access_criteria"] = out.toString(16).padStart(8 * Math.ceil(out.length / 8), '0')
        edit["start"] = startDate.getTime() / 1000
        edit["finish"] = (finishDate.getTime() / 1000) + 86399 // до конца дня
        //console.log(edit)
        sendDataToServer({
            ...edit,
            op: "editSmartcard"
        }).then(r => {
            if(r["status"]==="OK"){
                setEdit({})
                setDel(Math.random()-1)
            }
        })
    }

    function handleEditForm(e){
        let arr = {}
        if(e.target.name.startsWith("chk")){
            let newpack = edit["packages"]
            newpack.forEach(function(v, k){
                if(e.target.name==="chk"+v["id"]){
                    newpack[k]["on"] = e.target.checked
                }
            })
            arr = {...edit, packages: newpack}
        }else{
            arr = {...edit, [e.target.name]: e.target.value}
        }

        setEdit(arr)
    }


    function handleMultiplevalue(e){
        let value = e.target.value;
        if(value==="") value = 0

        const numValue = parseInt(value);
        if (numValue <= 1000) {
            setMultiplevalue(numValue);
        }
    }


    function handleMultipleAdd(qt){
        sendDataToServer({
            op: "addSmartcards",
            qt: qt
        }).then(r => {
            setMultiple(false)
            setSm(r)
            setRefcchk(r)
        })
    }





    function getIdsPckgs(ac){
        let integerValue = parseInt(ac, 16);
        let binaryString = integerValue.toString(2);
        binaryString = binaryString.padStart(32 * Math.ceil(binaryString.length / 32), '0');
        binaryString = binaryString.split("").reverse().join("");
        let out = []
        for(var i = 0; i < 32; i++){
            if(binaryString[i] === "1") {
                let ii = i+1
                out.push(ii)
            }
        }
        return out
    }


    function handleCchk(e){
        const id = parseInt(e.target.name.replace(/\D/g, ''))
        const checked = e.target.checked
        //let schk = []
        if(id !== 0){
            if(checked){
                setCchk([...cchk, id])
            }else{
                setCchk(cchk.filter(item => item !== id))
            }
        }else{
            if(checked){
                setCchk(refcchk.current)
            }else{
                setCchk([])
            }
            setCchk0(!cchk0)
        }
        //console.log(cchk)
    }



    function handleDownloadClick(){
        window.open(process.env.REACT_APP_EXPORT, '_blank');
    }




    function activate(m){
        sendDataToServer({
            op: "activate",
            ids: cchk,
            m
        }).then(r => {
            if(r["status"] === "OK"){
                setUpd(Math.random())
                setCchk([])
                setCchk0(false)
            }
        })
    }













    // ███████╗██╗██████╗ ███╗   ███╗██╗    ██╗ █████╗ ██████╗ ███████╗
    // ██╔════╝██║██╔══██╗████╗ ████║██║    ██║██╔══██╗██╔══██╗██╔════╝
    // █████╗  ██║██████╔╝██╔████╔██║██║ █╗ ██║███████║██████╔╝█████╗  
    // ██╔══╝  ██║██╔══██╗██║╚██╔╝██║██║███╗██║██╔══██║██╔══██╗██╔══╝  
    // ██║     ██║██║  ██║██║ ╚═╝ ██║╚███╔███╔╝██║  ██║██║  ██║███████╗
    // ╚═╝     ╚═╝╚═╝  ╚═╝╚═╝     ╚═╝ ╚══╝╚══╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚══════╝
                                                                    
    function firmware(id, mode){
        setIsloading(true)
        sendDataToServer({
            op: "firmware",
            mode: mode,
            id: id
        }).then(function(r){
            if(r.status === "OK"){
                setUpd(Math.random())
            }else{
                setFwError({'text': r.status, 'status': true})
                setTimeout(function(){
                    setFwError({'text': "", 'status': false})
                }, 5000)
            }
            setIsloading(false)
        })
    }


    // ███████╗██████╗  █████╗ ███████╗███████╗
    // ██╔════╝██╔══██╗██╔══██╗██╔════╝██╔════╝
    // █████╗  ██████╔╝███████║███████╗█████╗  
    // ██╔══╝  ██╔══██╗██╔══██║╚════██║██╔══╝  
    // ███████╗██║  ██║██║  ██║███████║███████╗
    // ╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝╚══════╝╚══════╝
                                            
    function erase(id, mode){
        setIsloading(true)
        sendDataToServer({
            op: "erase",
            mode: mode,
            id: id
        }).then(function(r){
            if(r.status === "OK"){
                setUpd(Math.random())
            }else{
                setFwError({'text': r.status, 'status': true})
                setTimeout(function(){
                    setFwError({'text': "", 'status': false})
                }, 5000)
            }
            setIsloading(false)
        })
    }


    // ██████╗ ███████╗███████╗███████╗████████╗    ██████╗ ██╗███╗   ██╗
    // ██╔══██╗██╔════╝██╔════╝██╔════╝╚══██╔══╝    ██╔══██╗██║████╗  ██║
    // ██████╔╝█████╗  ███████╗█████╗     ██║       ██████╔╝██║██╔██╗ ██║
    // ██╔══██╗██╔══╝  ╚════██║██╔══╝     ██║       ██╔═══╝ ██║██║╚██╗██║
    // ██║  ██║███████╗███████║███████╗   ██║       ██║     ██║██║ ╚████║
    // ╚═╝  ╚═╝╚══════╝╚══════╝╚══════╝   ╚═╝       ╚═╝     ╚═╝╚═╝  ╚═══╝
                                                                    

    function handleResetPin(serial){
        sendDataToServer({
            op: "resetPin",
            serial
        }).then(function(r){
            setFwError({'text': "PIN code for "+serial+" has been reset", 'status': true})
            setTimeout(function(){
                setFwError({'text': "", 'status': false})
            }, 3500)
        })
    }







                                                                                                                                                                                                                                                      
// ▄ ██╗▄▄ ██╗▄▄ ██╗▄▄ ██╗▄▄ ██╗▄▄ ██╗▄▄ ██╗▄▄ ██╗▄▄ ██╗▄▄ ██╗▄▄ ██╗▄▄ ██╗▄▄ ██╗▄▄ ██╗▄▄ ██╗▄▄ ██╗▄▄ ██╗▄▄ ██╗▄▄ ██╗▄▄ ██╗▄▄ ██╗▄▄ ██╗▄▄ ██╗▄▄ ██╗▄▄ ██╗▄▄ ██╗▄▄ ██╗▄▄ ██╗▄▄ ██╗▄▄ ██╗▄▄ ██╗▄▄ ██╗▄▄ ██╗▄▄ ██╗▄▄ ██╗▄▄ ██╗▄▄ ██╗▄▄ ██╗▄▄ ██╗▄▄ ██╗▄▄ ██╗▄
//  ████╗ ████╗ ████╗ ████╗ ████╗ ████╗ ████╗ ████╗ ████╗ ████╗ ████╗ ████╗ ████╗ ████╗ ████╗ ████╗ ████╗ ████╗ ████╗ ████╗ ████╗ ████╗ ████╗ ████╗ ████╗ ████╗ ████╗ ████╗ ████╗ ████╗ ████╗ ████╗ ████╗ ████╗ ████╗ ████╗ ████╗ ████╗ ████╗ ████╗ ████╗
// ▀╚██╔▀▀╚██╔▀▀╚██╔▀▀╚██╔▀▀╚██╔▀▀╚██╔▀▀╚██╔▀▀╚██╔▀▀╚██╔▀▀╚██╔▀▀╚██╔▀▀╚██╔▀▀╚██╔▀▀╚██╔▀▀╚██╔▀▀╚██╔▀▀╚██╔▀▀╚██╔▀▀╚██╔▀▀╚██╔▀▀╚██╔▀▀╚██╔▀▀╚██╔▀▀╚██╔▀▀╚██╔▀▀╚██╔▀▀╚██╔▀▀╚██╔▀▀╚██╔▀▀╚██╔▀▀╚██╔▀▀╚██╔▀▀╚██╔▀▀╚██╔▀▀╚██╔▀▀╚██╔▀▀╚██╔▀▀╚██╔▀▀╚██╔▀▀╚██╔▀▀╚██╔▀
//   ╚═╝   ╚═╝   ╚═╝   ╚═╝   ╚═╝   ╚═╝   ╚═╝   ╚═╝   ╚═╝   ╚═╝   ╚═╝   ╚═╝   ╚═╝   ╚═╝   ╚═╝   ╚═╝   ╚═╝   ╚═╝   ╚═╝   ╚═╝   ╚═╝   ╚═╝   ╚═╝   ╚═╝   ╚═╝   ╚═╝   ╚═╝   ╚═╝   ╚═╝   ╚═╝   ╚═╝   ╚═╝   ╚═╝   ╚═╝   ╚═╝   ╚═╝   ╚═╝   ╚═╝   ╚═╝   ╚═╝   ╚═╝ 
                                                                                                                                                                                                                                                      
                                                                                                                                                                                                                                                      

    

























// ██████╗ ███████╗████████╗██╗   ██╗██████╗ ███╗   ██╗
// ██╔══██╗██╔════╝╚══██╔══╝██║   ██║██╔══██╗████╗  ██║
// ██████╔╝█████╗     ██║   ██║   ██║██████╔╝██╔██╗ ██║
// ██╔══██╗██╔══╝     ██║   ██║   ██║██╔══██╗██║╚██╗██║
// ██║  ██║███████╗   ██║   ╚██████╔╝██║  ██║██║ ╚████║
// ╚═╝  ╚═╝╚══════╝   ╚═╝    ╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═══╝
    return( 
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h2><SimCardIcon style={{ marginRight: "10px" }} />Smartcards</h2>

                <span>
                    <TextField
                        style={{marginTop: "15px"}}
                        id="searchSM"
                        label="Search smartcard or decription"
                        type="search"
                        variant="standard"
                        onChange={handleSearch}
                        onClick={handleSearch}
                        value={search}
                    />
                </span>

                <span><PagesSmartcard onChange={(e) => {setPage(e); setCchk0(false); setCchk([]);}} /></span>

                <span className={Cookies.get('user') !== "admin" ? "hide" : ""}>
                    <Button onClick={handleAdd} style={{margin: "20px 5px 0 0"}} variant="contained"><AddCircleIcon /> NEW</Button>
                    {sm.length > 0 && (<>
                        <Button onClick={() => setMultiple(true)} style={{margin: "20px 5px 0 0"}} variant="contained"><AddCircleOutlineIcon /> MULTIPLE</Button> 
                        <Button onClick={handleDownloadClick} style={{margin: "20px 5px 0 0"}} variant="contained" color="success"><SendToMobileIcon /> EXPORT</Button>
                    </>)}
                    <FileUpload />
                </span>
            </div>
            <hr />

            <table width="100%" className="table_style">
                <thead><tr>
                    <td align="center"><Checkbox name="cchk0" checked={cchk0} size="small" onChange={handleCchk} /></td>
                    <td>Serial No</td>
                    <td>Description</td>
                    <td align="center">Packages</td>
                    <td>Start</td>
                    <td>Finish</td>
                    <td align="center">Pair</td>
                    <td align="center">Protect</td>
                    <td align="right">OPTIONS</td>
                </tr></thead>
                <tbody>

                    {
                        sm.length===0 ? <tr><td colSpan={9} style={{padding: "20px"}}><AlertNothing>No smartcards. Please add a new smartcard.</AlertNothing></td></tr> : 
                        sm.map((v,k) => (
                            <tr key={"td"+k} className={newRowHighlighted && k === 0 ? 'highlighted-row' : ''}>
                                <td align="center"><Checkbox size="small" checked={cchk.includes(parseInt(v["id"]))} onChange={handleCchk} name={"cchk"+v["id"]} /></td>
                                <td>{ins(mkSerialNo(v.serial_no), search)}</td>
                                <td>{ins(v.descr, search)}</td>
                                <td align="center">{getIdsPckgs(v.access_criteria).join(", ")}</td>
                                <td className={v.start > time ? "future-sub" : v.finish < time ? "last-sub" : ""}>{convUnixDate(v.start)}</td>
                                <td className={v.finish < time ? "last-sub" : v.start > time ? "future-sub" : ""}>{convUnixDate(v.finish)}</td>
                                <td align="center"><CheckSmartcard id={v.id} value={v.pair} kkey={"pair"} /></td>
                                <td align="center"><CheckSmartcard id={v.id} value={v.protect} kkey={"protect"} /></td>


                                <td align="right">
                                    <Tooltip title="Edit"><BorderColorIcon className="btnFirmware" onClick={() => handleClickEdit(v)} /></Tooltip>
                                    {v.mode==="1" ?
                                        <span className="mode">CON</span> : v.mode === "2"
                                            ? <span className="mode">IRD</span> : v.mode === "3"
                                                ? <span className="mode" style={{cursor:"pointer"}}
                                                    onClick={
                                                        ()=> {
                                                            sendDataToServer({
                                                                op: "getParamsNewcamd",
                                                                id: v.id
                                                            }).then(r => {
                                                                setParamsNewcamd([v.serial_no, r])
                                                                //console.log(r["conns"])
                                                            })
                                                        }
                                                    }>CAM</span> : <></>}



                                    {
                                      Cookies.get("user") === "admin" ? <>
                                        {v.mode==="0" ? <>
                                            <Tooltip title="CON"><SystemUpdateAltTwoToneIcon onClick={() => firmware(v.id, 1)} className="btnFirmware"/></Tooltip>
                                            <Tooltip title="IRD"><SystemUpdateAltTwoToneIcon onClick={() => firmware(v.id, 2)} className="btnFirmware"/></Tooltip>
                                            <Tooltip title="CAM"><SystemUpdateAltTwoToneIcon onClick={() => firmware(v.id, 3)} className="btnFirmware"/></Tooltip>
                                            </> : ""}
                                        <span className={(v.protect==="1" || v.mode==="0" || v.mode === "3") ? "eraser-off" : ""}>
                                            <Tooltip title="Return blank"><BackspaceIcon onClick={() => (v.protect==="0" && (v.mode==="1" || v.mode === "2") ? erase(v.id, 2) : "")} className="btnDelete"/></Tooltip>
                                            <Tooltip title="Counter disable"><AvTimerIcon onClick={() => (v.protect==="0" && (v.mode==="1" || v.mode === "2") ? erase(v.id, 1) : "")} className="btnDelete"/></Tooltip>
                                            {
                                                v.mode==="1" &&
                                                    <Tooltip title="Reset PIN"><LockResetIcon onClick={() => handleResetPin(v.serial_no)} className="btnFirmware"/></Tooltip> 
                                            }
                                        </span> 
                                        </> : ""
                                    }

                                </td>
                            </tr>




                        ))
                    }

                </tbody>
            </table>










{/* ██████╗ ██╗ █████╗ ██╗      ██████╗  ██████╗     ██████╗ ███████╗██╗     ███████╗████████╗███████╗
    ██╔══██╗██║██╔══██╗██║     ██╔═══██╗██╔════╝     ██╔══██╗██╔════╝██║     ██╔════╝╚══██╔══╝██╔════╝
    ██║  ██║██║███████║██║     ██║   ██║██║  ███╗    ██║  ██║█████╗  ██║     █████╗     ██║   █████╗  
    ██║  ██║██║██╔══██║██║     ██║   ██║██║   ██║    ██║  ██║██╔══╝  ██║     ██╔══╝     ██║   ██╔══╝  
    ██████╔╝██║██║  ██║███████╗╚██████╔╝╚██████╔╝    ██████╔╝███████╗███████╗███████╗   ██║   ███████╗
    ╚═════╝ ╚═╝╚═╝  ╚═╝╚══════╝ ╚═════╝  ╚═════╝     ╚═════╝ ╚══════╝╚══════╝╚══════╝   ╚═╝   ╚══════╝
                                                                                                   */}

            <Dialog open={parseInt(del) > 0 || del===-1 ? true : false} onClose={setDel}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
                <DialogContent>
                    <Alert color="info">
                        {`Removing a smartcard(s) from a billig means
                        it will be physically erased or reflashed!
                        Dont delete this if it remains in the subscriber's possession.`}
                    </Alert>    
                    <DialogContentText style={{marginTop:"20px", textAlign:"center"}} id="alert-dialog-description">
                        Are you sure delete this smartcard{(cchk.length>1 && del<1) && "s ("+cchk.length+" pcs)"}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => handleDelete(del)}>Yes</Button>
                    <Button onClick={() => setDel(0)}>No</Button>
                </DialogActions>
            </Dialog>








{/*
██████╗ ██╗ █████╗ ██╗      ██████╗  ██████╗     ███╗   ██╗███████╗██╗    ██╗ ██████╗ █████╗ ███╗   ███╗██████╗ 
██╔══██╗██║██╔══██╗██║     ██╔═══██╗██╔════╝     ████╗  ██║██╔════╝██║    ██║██╔════╝██╔══██╗████╗ ████║██╔══██╗
██║  ██║██║███████║██║     ██║   ██║██║  ███╗    ██╔██╗ ██║█████╗  ██║ █╗ ██║██║     ███████║██╔████╔██║██║  ██║
██║  ██║██║██╔══██║██║     ██║   ██║██║   ██║    ██║╚██╗██║██╔══╝  ██║███╗██║██║     ██╔══██║██║╚██╔╝██║██║  ██║
██████╔╝██║██║  ██║███████╗╚██████╔╝╚██████╔╝    ██║ ╚████║███████╗╚███╔███╔╝╚██████╗██║  ██║██║ ╚═╝ ██║██████╔╝
╚═════╝ ╚═╝╚═╝  ╚═╝╚══════╝ ╚═════╝  ╚═════╝     ╚═╝  ╚═══╝╚══════╝ ╚══╝╚══╝  ╚═════╝╚═╝  ╚═╝╚═╝     ╚═╝╚═════╝ 
*/}
            <Dialog open={paramsNewcamd[0] !== "" ? true : false}
                        aria-labelledby="alert-dialog-title1"
                        aria-describedby="alert-dialog-description1"
                >
                <DialogTitle id="alert-dialog-title1"><KeyIcon style={{position:"relative", top:"5px"}} />
                    {"Settings for Virtual CAM "} <span className="ins">#{mkSerialNo(paramsNewcamd[0])}</span>
                    <hr />
                </DialogTitle>
                <DialogContent>
                    <Alert color="info">
                        {`You can enter these settings in the client (Oscam, Cesbo Astra, STB, etc.), which will connect to the Virtual CAM TVCAS4 to receive keys CW1/CW2.`}
                    </Alert>    
                    <DialogContentText style={{marginTop:"20px", textAlign:"center"}} id="alert-dialog-description">
                        <textarea value={paramsNewcamd[1]["status"]} readOnly style={{width:"100%", height:"170px"}} />
                    </DialogContentText>
                    <Alert variant="outlined" severity="warning" icon={<SettingsEthernetIcon />}>
                        <AlertTitle>Connections:</AlertTitle>
                        <pre>{paramsNewcamd[1]["conns"] === "" ? "no connections" : paramsNewcamd[1]["conns"]}</pre>
                    </Alert>
                    <p style={{fontSize: "11px"}}><i><b style={{color:"red"}}>Banned IP</b> (<Link to="#" onClick={() => {
                        sendDataToServer({op: "clearBanned"}).then(() => {
                            setParamsNewcamd(["", {}]) 
                        })
                    }
                    }>clear</Link>)</i> {paramsNewcamd[1]["banned"] === "" ? "😃" : paramsNewcamd[1]["banned"]}</p>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={
                        () => {
                            setParamsNewcamd(["", {}]) 
                        }
                    }>Close</Button>
                </DialogActions>
            </Dialog>







{/* ███████╗██████╗ ██╗████████╗    ███████╗ ██████╗ ██████╗ ███╗   ███╗
    ██╔════╝██╔══██╗██║╚══██╔══╝    ██╔════╝██╔═══██╗██╔══██╗████╗ ████║
    █████╗  ██║  ██║██║   ██║       █████╗  ██║   ██║██████╔╝██╔████╔██║
    ██╔══╝  ██║  ██║██║   ██║       ██╔══╝  ██║   ██║██╔══██╗██║╚██╔╝██║
    ███████╗██████╔╝██║   ██║       ██║     ╚██████╔╝██║  ██║██║ ╚═╝ ██║
    ╚══════╝╚═════╝ ╚═╝   ╚═╝       ╚═╝      ╚═════╝ ╚═╝  ╚═╝╚═╝     ╚═╝
                                                                     */}


            <Modal
                open={"id" in edit? true : false}
                onClose={() => setEdit({})}
                aria-labelledby="edit-modal-title"
                aria-describedby="edit-modal-description"
            >
                <Box sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 400,
                            bgcolor: 'background.paper',
                            border: '1px solid #000',
                            boxShadow: 10,
                            p: 4,
                }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <BorderColorIcon /> Edit smartcard <span className="ins">#{"id" in edit ? mkSerialNo(edit.serial_no) : ""}</span>
                    </Typography>
                    <hr style={{marginBottom:"30px"}} />
                    <form onSubmit={handleSendForm} >
                            <TextField
                                fullWidth   
                                value={edit.descr}
                                label="Description"
                                name={"descr"}
                                onChange={handleEditForm}
                                style={{marginBottom:"15px"}}
                            />
                            {
                                ("packages" in edit) &&
                                edit["packages"].length > 0 ? 
                                    edit["packages"].map((v, k) => (
                                        <FormControlLabel key={k} control={
                                            <Checkbox 
                                                size="small"
                                                checked={v["on"]}
                                                name={"chk"+v["id"]}
                                                onChange={handleEditForm}
                                            />}
                                            label={v.descr}
                                            style={{color:v["pin"] === "1" && "red"}}
                                        />
                                    )) :
                                <div style={{marginTop:"10px", color: "red"}}>No packages!</div>
                            }
                            
                            <DatePicker
                                style={{color: "red"}}
                                selectsRange={true}
                                startDate={startDate}
                                finishDate={finishDate}
                                endDate={finishDate}
                                onChange={setDateRange}
                                dateFormat="dd.MM.yyyy"
                            />

                            <Grid container style={{marginTop:"25px"}}>
                                <Grid item xs={3}>
                                    {Cookies.get("user") === "admin" ? 
                                    <FormControl size="small">
                                        <InputLabel id="mode-select-label1">Mode</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label1"
                                            id="demo-simple-select1"
                                            value={edit.mode}
                                            label="Mode"
                                            onChange={(e) => {
                                                const mm = e.target.value
                                                if (mm === 0 && last_mm.current !== "0"){
                                                    setMinfo("EMM-key will be update!")
                                                }else{
                                                    setMinfo("")
                                                }
                                                setEdit({...edit, mode: mm})
                                            }}
                                        >
                                            <MenuItem value={0}>Off</MenuItem>
                                            <MenuItem value={1}>CON</MenuItem>
                                            <MenuItem value={2}>IRD</MenuItem>
                                            <MenuItem value={3}>CAM</MenuItem>
                                        </Select>
                                        <div style={{fontSize: "11px", color:"red", fontStyle:"italic"}}>{mInfo}</div>
                                    </FormControl> : ""
                                    }
                                </Grid>
                                <Grid item xs={3}>
                                    {
                                        ((edit.mode==="1" || edit.mode==="2") && Cookies.get("user") === "admin") &&
                                        <div style={{marginTop:"8px"}}>
                                            <Tooltip title="Program smartcard AMD64">
                                                <SimCardDownloadIcon className='tipa-a' onClick={() => {
                                                    let mac = prompt("MAC-address for STB\nXX:XX:XX:XX:XX:XX - recommended\n0 - any", 0)
                                                    // проверяем мак-адрес
                                                    if (mac === "0" || /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$|^([0-9A-Fa-f]{2}){6}$/.test(mac)){
                                                        window.location.href="/smartcard_amd64/?sn="+edit.serial_no+"&mac="+mac+"&mode="+edit.mode}
                                                    }
                                                }/>
                                            </Tooltip>
                                            <Tooltip title="Program smartcard ARM64">
                                                <SimCardDownloadIcon className='tipa-a' onClick={() => {
                                                    let mac = prompt("MAC-address for STB\nXX:XX:XX:XX:XX:XX - recommended\n0 - any", 0)
                                                    // проверяем мак-адрес
                                                    if (mac === "0" || /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$|^([0-9A-Fa-f]{2}){6}$/.test(mac)){
                                                        window.location.href="/smartcard_arm64/?sn="+edit.serial_no+"&mac="+mac+"&mode="+edit.mode}
                                                    }
                                                }/>
                                            </Tooltip>
                                        </div>
                                    }
                                </Grid>
                                <Grid item xs={6} style={{textAlign:"right", marginTop:"3px"}}>
                                        <Button variant="contained" type="submit"><BorderColorIcon style={{fontSize:"14px", marginRight:"10px"}} /> Save</Button>
                                        <Button variant="outlined" style={{marginLeft:"5px"}} type="submit" onClick={() => setEdit({})}>Cancel</Button>
                                </Grid>
                            </Grid>
                    </form>
                </Box>
            </Modal>





{/* ███╗   ███╗██╗   ██╗██╗  ████████╗██╗██████╗ ██╗     ███████╗    ███████╗ ██████╗ ██████╗ ███╗   ███╗
    ████╗ ████║██║   ██║██║  ╚══██╔══╝██║██╔══██╗██║     ██╔════╝    ██╔════╝██╔═══██╗██╔══██╗████╗ ████║
    ██╔████╔██║██║   ██║██║     ██║   ██║██████╔╝██║     █████╗      █████╗  ██║   ██║██████╔╝██╔████╔██║
    ██║╚██╔╝██║██║   ██║██║     ██║   ██║██╔═══╝ ██║     ██╔══╝      ██╔══╝  ██║   ██║██╔══██╗██║╚██╔╝██║
    ██║ ╚═╝ ██║╚██████╔╝███████╗██║   ██║██║     ███████╗███████╗    ██║     ╚██████╔╝██║  ██║██║ ╚═╝ ██║
    ╚═╝     ╚═╝ ╚═════╝ ╚══════╝╚═╝   ╚═╝╚═╝     ╚══════╝╚══════╝    ╚═╝      ╚═════╝ ╚═╝  ╚═╝╚═╝     ╚═╝
                                                                                                      */}
            <Modal
                open={multiple}
                onClose={() => setMultiple(false)}
                aria-labelledby="edit-modal-title"
                aria-describedby="edit-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    border: '1px solid #000',
                    boxShadow: 10,
                    p: 4,
                }}>

                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <AddCircleOutlineIcon style={{fontSize:"16px"}} /> Create multiple smartcards
                    </Typography>
                    <Alert severity="warning">The data to be filled in will be taken from the <b>last created smartcard</b>!</Alert>
                        <center>
                        <TextField
                            label="Enter quantity 10...1000"
                            style={{margin:"20px 0"}}
                            value={multipleValue} 
                            onChange={handleMultiplevalue}
                        />
                        <Button style={{margin:"30px 0 0 10px"}} onClick={() => handleMultipleAdd(multipleValue)}>Go!</Button>
                        </center>
                </Box>

            </Modal>





    {/* ███████╗███╗   ██╗ █████╗  ██████╗██╗  ██╗██████╗  █████╗ ██████╗ 
        ██╔════╝████╗  ██║██╔══██╗██╔════╝██║ ██╔╝██╔══██╗██╔══██╗██╔══██╗
        ███████╗██╔██╗ ██║███████║██║     █████╔╝ ██████╔╝███████║██████╔╝
        ╚════██║██║╚██╗██║██╔══██║██║     ██╔═██╗ ██╔══██╗██╔══██║██╔══██╗
        ███████║██║ ╚████║██║  ██║╚██████╗██║  ██╗██████╔╝██║  ██║██║  ██║
        ╚══════╝╚═╝  ╚═══╝╚═╝  ╚═╝ ╚═════╝╚═╝  ╚═╝╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═╝
                            SNACKBAR для опций с выбранными картами                        */}

            <Snackbar
                open={parseInt(cchk) > 0 ? true : false }
                anchorOrigin={{vertical:"bottom", horizontal:"left"}}
            >
                <Alert
                    severity="info"
                    sx={{ width: '100%' }}
                >
                    <div style={{marginBottom: "10px"}}>Selected smartcards: {cchk.length}</div>

                    <Button onClick={() => activate(0)} className="snacksm-btn" variant="contained" color="error"><ThumbDownOffAltIcon /> Deactivate</Button>
                    <Button onClick={() => activate(1)} className="snacksm-btn" variant="contained" color="success"><ThumbUpOffAltIcon /> Activate</Button>
                    <span className={Cookies.get('user') !== "admin" ? "hide" : "btnDeleteSm"}><DeleteSweepIcon onClick={() => setDel(-1)} /></span>
                </Alert>
            </Snackbar>

            {/* SNACKBAR для ошибки об неуспешном подключении  */}
            <Snackbar open={fwError.status} anchorOrigin={{vertical:"bottom", horizontal:"center"}}>
                <Alert severity={ fwError.text.indexOf("PIN") === 0 ? "success" : "error" } variant="filled" sx={{ width: '100%' }}>
                    <div style={{marginBottom: "10px"}}>{fwError.text}</div>
                </Alert>
            </Snackbar>




{/* ██████╗  █████╗  ██████╗██╗  ██╗██████╗ ██████╗  ██████╗ ██████╗ 
    ██╔══██╗██╔══██╗██╔════╝██║ ██╔╝██╔══██╗██╔══██╗██╔═══██╗██╔══██╗
    ██████╔╝███████║██║     █████╔╝ ██║  ██║██████╔╝██║   ██║██████╔╝
    ██╔══██╗██╔══██║██║     ██╔═██╗ ██║  ██║██╔══██╗██║   ██║██╔═══╝ 
    ██████╔╝██║  ██║╚██████╗██║  ██╗██████╔╝██║  ██║╚██████╔╝██║     
    ╚═════╝ ╚═╝  ╚═╝ ╚═════╝╚═╝  ╚═╝╚═════╝ ╚═╝  ╚═╝ ╚═════╝ ╚═╝      */}
                                                                 
            <Backdrop open={isLoading} style={{ zIndex: 999999 }}>
                <CircularProgress style={{color: "white"}} />
            </Backdrop>

        </>
    )
}