import React, { useEffect, useState } from "react";
import StorageIcon from '@mui/icons-material/Storage';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, MenuItem, Select, Switch, TextField, Tooltip } from "@mui/material";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { convUnixDate, mkSerialNo, sendDataToServer } from "../utils/functions";
import Cookies from "js-cookie";
import Slider from '@mui/material/Slider';



export default function EmmG({data}){

    const [enable, setEnable] = useState(data.enable==="1" ? true : false)
    const [del, setDel] = useState(false)
    const [show, setShow] = useState("server")
    const [start, setStart] = useState(0)
    const [connect, setConnect] = useState(0)
    const [serial, setSerial] = useState("")
    const [ddescr, setDdescr] = useState("")
    const [daddr, setDaddr] = useState("")
    const [blocked, setBlocked] = useState(false)
    const [mode, setMode] = React.useState(data.mode);
    const [speed, setSpeed] = useState(10);
    const [priority, setPriority] = useState(false);
    const [lic, setLic] = useState("0");


    useEffect(() => {
        const updateEverySecond = () => {
            sendDataToServer({'op':"checkEMMconnect", 'id': data.id}).then(r => {  
                if (r[0]) {
                    setLic(r[0].lic)
                    setStart(r[0].start)
                    setConnect(parseInt(r[0].connect)+1)
                    setSerial(mkSerialNo(r[0].serial_no))
                    if(r[0].speed > 0 && r[0].speed <=100){
                        setSpeed(r[0].speed)
                    }else{
                        setSpeed(10)
                    }
                    setPriority(r[0].priority==="0"?false:true)
                    //setMode(r[0].mode)
                }
            })
        }
        updateEverySecond()
        const intervalId = setInterval(updateEverySecond, 1000);
        return () => clearInterval(intervalId);
    // eslint-disable-next-line
    }, [])




    function handleEnable(e, id){
        var chk = e.target.checked
        sendDataToServer({'op':"enableEMMG", 'id': id, 'enable': (chk ? "1" : "0")}).then(r => {
            if (r.status === "OK"){
                setEnable(chk)
                if(!chk){
                    setBlocked(true)
                    setTimeout(function(){
                        setBlocked(false)
                    }, 3000)
                }
            }
        })
    }

    function handleConfirm(id){
        sendDataToServer({'op':"deleteEMMG", 'id': id}).then(r => {
                setDel(false)  
                if (r.status === "OK"){
                    setShow("server hide")
                }
            }
        )
        
    }


    function handleDelete(id){
        setDel(true)
    }

    function connTime(ttt){
        if(ttt==="" || ttt==="0" || ttt<10){
            return ""
        }else{
            return convUnixDate(ttt)
        }
    }


    function handleEdit(e, k, id){
        var v = e.target.value
        sendDataToServer({'op': "editEMMG", 'row': k, 'value': v, 'id': id}).then(r => {
            if(r["status"]==="OK"){
                if (k==="descr")setDdescr(v)
                if (k==="addr")setDaddr(v)
            }
        })
    }


    function handleMode(e, id){
        var v = e.target.value
        sendDataToServer({'op': "modeEmmg", 'mode': v, 'id': id}).then(r => {
            if(r["status"]==="OK"){
                setMode(v)
                //console.log(v)
            }
        })
    }



    function handleSpeedComm(v,id){
        setSpeed(v)
        sendDataToServer({'op': "speedEmmg", 'speed': v, 'id': id})
    }



    function handleChangePriotiy(en, id){
        sendDataToServer({'op': "priorityEmmg", en: en?"1":"0", 'id': id}).then(r=>{
            if(r["status"]==="OK"){
                setPriority(en)
            }
        })
    }



    return (
        <div className={show} style={{animation: enable ? "moveStripes 20s linear infinite" : "none" }} >
            <Grid container>
                <Grid item style={{textAlign:"center"}} xs={3}>
                    <StorageIcon style={{fontSize:"70px"}} />
                    <div>{"EMMG #" + data.id}</div>
                </Grid>
                <Grid item xs={8}>
                    <div><TextField
                        disabled={enable}
                        className="text-serverg"
                        variant="filled"
                        label="Description"
                        id={"text-descr-emm"+data.id}
                        size="small"
                        style={{marginRight:"5px"}}
                        value={ddescr ? ddescr : data.descr}
                        // была ошибка в браузере..
                        //onChangeCommitted={e => handleEdit(e, "descr", data.id)}
                        onChange={e => handleEdit(e, "descr", data.id)}
                    />
                    <TextField
                        disabled={enable}
                        className="text-serverg"
                        variant="filled"
                        label="Host address"
                        id={"text-host-emm"+data.id}
                        size="small"
                        value={daddr ? daddr : data.addr}
                        onChange={e => handleEdit(e, "addr", data.id)}
                    /></div>
                    <table width="100%"><tbody>
                    <tr><td width="40%"><div className="server-connect">
                        <div><b>Start</b>: <span>{connTime(start)}</span></div>
                        <div><b>Connect</b>: <span>{connTime(connect)}</span></div>
                        <div><b>Serial</b>: <span>{serial}</span></div>
                    </div></td>

                    <td width="30%">

                        <table><tbody><tr>
                        <td><center style={{font:"10px/1em Verdana", paddingTop: "15px"}}>speed <b>{speed}%</b></center>
                            <Slider
                                onChangeCommitted={(e,v) => handleSpeedComm(v,data.id)}
                                size="small"
                                min={1}
                                max={100}
                                defaultValue={10}
                                aria-label="Small"
                                valueLabelDisplay="auto"
                                disabled={lic==="0" ? true : enable}
                                value={parseInt(speed)}
                            /></td>
                            <td width="10%" style={{paddingLeft:"10px"}}>
                                <Tooltip title="Priority EMMs" placement="left">
                                    <Checkbox
                                        disabled={enable}
                                        checked={priority}
                                        size="small"
                                        onChange={e =>{
                                            handleChangePriotiy(e.target.checked, data.id)
                                        }}
                                    />
                                </Tooltip>
                            </td>
                        </tr></tbody></table>
                    </td>

                    <td align="right">
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <Select
                            labelId="demo-select-mode"
                            id="select-mode"
                            value={mode}
                            onChange={(e) => handleMode(e, data.id)}
                            disabled={enable}
                
                        >
                            <MenuItem value="1">[ CON ]</MenuItem>
                            <MenuItem value="2">[ IRD ]</MenuItem>
                        </Select>
                        </FormControl>
                      </td></tr>



                    </tbody></table>
                </Grid>
                <Grid item xs={1}>
                    <div style={{marginTop:"10px"}}>
                        <Switch disabled={blocked === true} aria-label="on-off" id={"on-off"+data.id} checked={enable} onChange={e => 
                            handleEnable(e, data.id)
                         } />
                    </div>
                    <div style={{marginTop:"10px"}}>
                        {(!blocked && !enable && Cookies.get("user") === "admin") && <Button onClick={handleDelete}><DeleteOutlineIcon /></Button>}
                    </div>
                </Grid>
            </Grid>

            <Dialog open={del} onClose={setDel}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
                <DialogContent><DialogContentText id="alert-dialog-description">
                    Are you sure remove EMMG #{data.id}?
                </DialogContentText></DialogContent>
                <DialogActions>
                    <Button onClick={() => handleConfirm(data.id)}>Yes</Button>
                    <Button autoFocus onClick={() => setDel(false)}>No</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}